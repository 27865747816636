
import {
  ref,
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  watchEffect,
} from "vue";

export default {
  props: {
    hj: Object,
    NewIsLive: String,
  },
  setup(props, context) {
    const state = reactive({
      double: computed(() => {
        return props.hj;
      }),
    });
    return {
      ...toRefs(state),
    };
  },
};
