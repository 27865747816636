import { createHash } from 'crypto';
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

// const routes: Array<RouteRecordRaw> = [

// ];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes: [{
    path: "/",
    name: "Home",
    meta: {
      index: 1
    },
    component: Home
  },
  {
    path: "/ClassifyList/:id/:name/:isid",
    name: "ClassifyList",
    meta: {
      index: 2
    },
    // route level code-splitting
    // this generates a separate chunk (about123.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "ClassifyList" */ "../views/ClassifyList.vue")
  },
  {
    path: "/LandscapeVideo/:id/:islive",
    name: "LandscapeVideo",
    meta: {
      index: 3
    },
    // route level code-splitting
    // this generates a separate chunk (about123.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "LandscapeVideo" */ "../views/LandscapeVideo.vue")
  },
  {
    path: "/VerticalScreenVideo/:id/:islive",
    name: "VerticalScreenVideo",
    meta: {
      index: 4
    },
    // route level code-splitting
    // this generates a separate chunk (about123.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "VerticalScreenVideo" */ "../views/VerticalScreenVideo.vue")
  },]
});

export default router;
