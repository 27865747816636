<template>
  <div class="vsbanner" v-if="double">
    <van-swipe :autoplay="3000">
      <van-swipe-item
        v-for="(image, index) in double"
        :title="index"
        :key="index"
      >
        <router-link
          :to="{
            name: 'LandscapeVideo',
            params: {
              id: image.v_id,
              islive: image.is_live,
            },
          }"
          ><img :src="image.img" :alt="image.v_id"
        /></router-link>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script lang="ts">
import {
  ref,
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  watchEffect,
} from "vue";

export default {
  props: {
    hj: Object,
    NewIsLive: String,
  },
  setup(props, context) {
    const state = reactive({
      double: computed(() => {
        return props.hj;
      }),
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
.vsbanner {
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    width: 100%;
    padding: 10px 20px 60px;
    box-sizing: border-box;
    text-align: center;
    background-color: #000;
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
  .van-swipe__indicators {
    bottom: 30px;
    right: 0;
    left: auto;
    .van-swipe__indicator {
      width: 12px;
      height: 12px;
      margin-left: 10px;
      border-radius: 50%;
      &.van-swipe__indicator--active {
        background-color: #fff;
        width: 20px;
        border-radius: 6px;
      }
    }
  }
}
</style>