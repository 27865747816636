<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  props: {
    msg: String,
  },
};
</script>


<style lang="scss">
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 解决IOS默认滑动很卡的情况 */
  -webkit-overflow-scrolling: touch;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body,
p,
div,
span strong,
i,
ul,
li {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-toast {
  width: 200px;
  height: 200px;
}
.van-toast__text {
  font-size: 30px;
  padding: 10px;
}
.van-loading__spinner {
  width: 40px;
  height: 40px;
}
.van-empty__image {
  width: 400px;
  height: 400px;
}
.van-empty__description {
  font-size: 30px;
}

.van-loading .van-loading--circular {
  padding: 10px;
}
.van-list__finished-text {
  font-size: 28px;
  padding: 14px 0;
}
.van-loading__text {
  font-size: 28px;
  padding-bottom: 14px;
  position: relative;
  top: 5px;
}
.van-pull-refresh__head {
  height: 80px;
}
.van-pull-refresh__head div {
  font-size: 24px;
}
.van-image-preview .van-image-preview__close-icon {
  top: 20px;
  font-size: 50px;
}
.van-image__error .van-icon-photo-fail {
  font-size: 200px;
}
.van-image-preview .van-image-preview__index {
  font-size: 30px;
  line-height: 2;
}
.van-popup__close-icon {
  font-size: 45px;
}
</style>
