<template>
  <section>
    <div class="subheading">
      <div class="text">直播回放</div>
      <router-link
        :to="{
          name: 'ClassifyList',
          params: { id: idd, name: '直播回放', isid: NewIsLive },
        }"
        ><div class="more">更多<i class="icon"></i></div
      ></router-link>
    </div>
    <div class="playbacklist">
      <router-link
        :to="{
          name: 'LandscapeVideo',
          params: {
            id: item.v_id,
            islive: NewIsLive,
          },
        }"
        class="items"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="img">
          <img :src="item.cover_img" alt="" />
          <i class="icon"></i>
        </div>
        <p class="tit">{{ item.v_name }}</p>
        <p class="hint" v-html="item.v_keywords"></p>
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, toRefs } from "vue";

export default defineComponent({
  name: "VideoPlayback",
  props: {
    hj: Array,
    idnum: Number,
    NewIsLive: String,
  },
  setup(props, context) {
    const state = reactive({
      list: computed(() => {
        return props.hj;
      }),
      idd: computed(() => {
        return props.idnum;
      }),
    });
    return {
      ...toRefs(state), //将reactive转化为ref
    };
  },
});
</script>

 <style lang="scss">
.subheading {
  margin: 40px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .text {
    font-size: 32px;
    line-height: 1.2;
    font-family: PingFang SC;
    color: #000;
  }
  .more {
    font-size: 24px;
    line-height: 1.2;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      display: block;
      height: 28px;
      width: 28px;
      background: url(../assets/arrows.png) no-repeat;
      background-size: 28px;
    }
  }
}
.playbacklist {
  text-align: left;
  margin: 0 20px;
  overflow: hidden;
  display: flex;
  .items {
    width: 165px;
    display: block;
    margin-left: 20px;
    &.items:first-child {
      margin-left: 0;
    }
    .img {
      display: block;
      position: relative;
      margin-bottom: 15px;
      width: 165px;
      height: 205px;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .icon {
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: 20px;
        height: 14px;
        background: url(../assets/icon-video.png) no-repeat;
        background-size: 20px;
      }
    }
    .tit {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hint {
      margin-top: 10px;
      height: 26px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #9a9a9a;
      overflow: hidden;
      img {
        display: none;
      }
    }
  }
}
</style>
