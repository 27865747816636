import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'lib-flexible/flexible.js'
import { Swipe, Overlay, SwipeItem, Popup, Lazyload, Toast, NavBar, List, Tab, Tabs, Sticky, ShareSheet, PullRefresh } from 'vant';
 

createApp(App)
  .use(store)
  .use(router)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(NavBar)
  .use(List)
  .use(Tab)
  .use(Popup)
  .use(Tabs)
  .use(Sticky)
  .use(ShareSheet)
  .use(Overlay)
  .use(PullRefresh)
  .mount("#app");
