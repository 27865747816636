// 写法一：适合分类导出
import http from '../utils/http'
import axios from 'axios'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
 
// const resquest = "https://video-ceshi.sctx.com/video_api/"
const resquest = "https://video.sctx.com/video_api/"

// get请求
// export function getListAPI(params){
//     return http.get(`${resquest}/getIntegralLog.api`,params)
// }
// post请求

export function videochannel(params) {
    return http.post(`${resquest}index`, params)
}

export function videochannelList(params) {
    return http.post(`${resquest}videochannel`, params)
}


export function liveCatdata(params) {
    return http.post(`${resquest}live-catdata`, params)
}

export function moreComment(params) {
    return http.post(`${resquest}more-comment`, params)
}

export function videochannelinfo(params) {
    return http.post(`${resquest}videochannelinfo`, params)
}

export function commentAdd(params) {
    return http.post(`${resquest}comment-add`, params)
}

export function likelollection(params) {
    return http.post(`${resquest}likelollection`, params)
}

export function commentPraise(params) {
    return http.post(`${resquest}comment-praise`, params)
}