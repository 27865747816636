<template>
  <div class="home">
    <NavBar back="/" msg="视频频道" openLeft="0" />
    <VanSwipe :hj="homejson.advertisement" :NewIsLive="isLive" />
    <Category :hj="homejson.Navigation" :NewIsLive="isLive" />
    <VideoPlayback
      :hj="homejsonTwo"
      :idnum="homejsonTwoId"
      :NewIsLive="isLive"
    />
    <div class="subheading">
      <div class="text">精选视频</div>
    </div>
    <!-- animated swipeable -->
    <van-tabs v-model="active">
      <van-tab
        :title="item.vc_name"
        v-for="(item, index) in homejson.class_data2"
        :key="index"
      >
        <VideoList :hj="item.vc_id" :newisid="item.is_live" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import VanSwipe from "@/components/VanSwipe.vue";
import NavBar from "@/components/NavBar.vue";
import Category from "@/components/Category.vue";
import VideoPlayback from "@/components/VideoPlayback.vue";
import VideoList from "@/components/VideoList.vue";

import { videochannel } from "@/api/api";
export default {
  name: "Home",
  components: {
    VanSwipe,
    NavBar,
    Category,
    VideoPlayback,
    VideoList,
  },
  data() {
    return {
      active: 1,
      homejson: [],
      homejsonTwo: [],
      homejsonTwoId: 1,
      num: 10,
      isLive: 0,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute(
        "style",
        "font-size:12px;touch-action: pan-y;-webkit-overflow-scrolling: touch;"
      );
  },
  mounted() {},
  methods: {
    async loadrule() {
      await videochannel()
        .then(({ data: { data } }) => {
          console.log(data);
          this.homejson = data;
          this.homejsonTwo = data.class_data1.data;
          this.homejsonTwoId = data.class_data1.vc_id;
          this.isLive = data.class_data1.is_live; // New is_live
        })
        .catch((err) => console.log("失败了" + err));
    },
  },
  created() {
    this.loadrule();
  },
};
</script>

<style lang="scss">
.subheading {
  margin: 40px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .text {
    font-size: 32px;
    line-height: 1.2;
    font-family: PingFang SC;
    color: #000;
  }
}
.van-tabs {
  width: 750px;
}
.van-tabs__nav {
  margin: 0 20px;
  .van-tabs__line {
    width: 4em;
    height: 4px;
  }
  .van-tab {
    &.van-tab--active {
      .van-tab__text {
        color: #a81211;
      }
    }
    .van-tab__text {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 40px;
    }
  }
}
</style>