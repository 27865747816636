
// import component from "*.vue";
import { ref, computed, defineComponent, reactive, toRefs, watch } from "vue";

export default defineComponent({
  name: "Category",
  props: {
    hj: Array,
    NewIsLive:Array
  },
  setup(props, context) {
    console.log("props:", { ...props.hj });
    const state = reactive({
      classify: computed(() => {
        return props.hj;
      }),
    });
    return {
      ...toRefs(state),
    };
  },
});
