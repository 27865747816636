
import { defineComponent, computed, reactive, toRefs } from "vue";

export default defineComponent({
  name: "VideoPlayback",
  props: {
    hj: Array,
    idnum: Number,
    NewIsLive: String,
  },
  setup(props, context) {
    const state = reactive({
      list: computed(() => {
        return props.hj;
      }),
      idd: computed(() => {
        return props.idnum;
      }),
    });
    return {
      ...toRefs(state), //将reactive转化为ref
    };
  },
});
