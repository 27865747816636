<template>
  <div class="category">
    <router-link
      :to="{
        name: 'ClassifyList',
        params: { id: item.vc_id, name: item.vc_name, isid: NewIsLive },
      }"
      class="item"
      v-for="(item, index) in classify"
      :key="item.vc_id"
      :title="index"
    >
      <img :src="item.vc_img" :alt="item.vc_id" />
      <span>{{ item.vc_name }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
// import component from "*.vue";
import { ref, computed, defineComponent, reactive, toRefs, watch } from "vue";

export default defineComponent({
  name: "Category",
  props: {
    hj: Array,
    NewIsLive:Array
  },
  setup(props, context) {
    console.log("props:", { ...props.hj });
    const state = reactive({
      classify: computed(() => {
        return props.hj;
      }),
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

 
<style lang="scss">
.category {
  margin: 20px 0;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    width: 100%;
    height: 90px;
    margin: 12px;
    background: #f5f6fa;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      top: 6px;
      height: 60px;
    }
    span {
      display: block;
      width: 70px;
      font-size: 28px;
      color: #828894;
    }
  }
}
</style>
