<template>
  <van-nav-bar :title="msg" v-if="openLeft == 0" />
  <van-nav-bar v-else :title="msg" left-arrow @click-left="onClickLeft" />
</template>

<script>
export default {
  name: "NavBar",
  props: {
    msg: String,
    openLeft: String,
    back: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClickLeft() {
      if (!this.back) {
        if (window.history.length <= 1) {
          this.$router.push({ path: "/" });
          return false;
        } else {
          this.$router.go(-1);
        }
      } else {
        this.$router.push({ path: this.back });
      }
      // ctx.emit("callback");
    },
  },
};
</script>

 <style lang="scss">
.van-nav-bar {
  background: #000;
  height: 90px;
  &:after {
    border: 0;
  }
  .van-nav-bar__left {
    .van-icon {
      font-size: 36px;
      color: #fff;
    }
  }
  .van-nav-bar__title {
    &.van-ellipsis {
      font-size: 36px;
      color: #fff;
    }
  }
}
</style>
