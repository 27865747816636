<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    :finished-text="list == 0 ? '此频道还没发布' : '我是有底线的'"
    @load="loadMore"
  >
    <div class="videolist">
      <router-link
        :to="{
          name: item.v_class == 0 ? 'LandscapeVideo' : 'VerticalScreenVideo',
          params: {
            id: item.v_id,
            islive: newisid,
          },
        }"
        class="items"
        v-for="(item, index) in list"
        :key="index"
        :title="item.v_id"
      >
        <div class="img">
          <img :src="item.v_img" alt="" />
          <div class="info">
            <div class="box">
              <i class="icon"></i>
              <p>{{ item.v_click_sum }}播放</p>
            </div>
            <div class="box">{{ item.Commentsum }}评论</div>
          </div>
        </div>
        <p class="text">{{ item.v_name }}</p>
        <p class="hint" v-html="item.v_keywords"></p>
      </router-link>
    </div>
  </van-list>
</template>

<script>
import { defineComponent, toRefs, reactive, computed, onMounted } from "vue";
import { videochannelList } from "@/api/api";
import { Empty } from "vant";
export default defineComponent({
  name: "VideoList",
  props: {
    hj: String,
    newisid: String,
  },
  setup(props) {
    const state = reactive({
      list: [],
      page: 0, //页数
      loading: false,
      finished: false,
      timer: null,
    });
    const loadMore = async () => {
      state.page += 1;
      console.log(state.page);
      await onLoad();
    };
    const onLoad = () => {
      state.timer = setTimeout(() => {
        const data = {
          data: `{"page":"${state.page}","vc_id":"${props.hj}","is_live":"${props.newisid}"}`,
        };
        videochannelList(data)
          .then(({ data: { data } }) => {
            state.list = [...state.list, ...data.data];
            console.log(data);
            state.loading = false;
            if (state.list.length == data.data_number) {
              state.finished = true;
            } else {
              state.finished = false;
            }
          })
          .catch((err) => {
            state.finished = true;
            console.log("失败了" + err);
          });
      }, 500);
    };
    return {
      ...toRefs(state), //将reactive转化为ref
      loadMore,
      onLoad,
    };
  },
});
</script>

<style lang="scss">
.videolist {
  position: relative;
  margin-top: 20px;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  .items {
    text-align: left;
    width: 345px;
    margin: 10px 10px 20px;
    .img {
      position: relative;
      width: 345px;
      height: 220px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .info {
        position: absolute;
        bottom: 10px;
        left: 10px;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        .box {
          padding: 5px 10px;
          display: flex;
          align-items: center;
        }
        .box:nth-child(1) {
          background: #aa1212;
          .icon {
            display: block;
            width: 24px;
            height: 18px;
            background: url(../assets/icon-video.png) no-repeat;
            background-size: 24px;
          }
          p {
            margin-left: 10px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .box:nth-child(2) {
          background: #000;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .text {
      margin: 10px 0;
      font-size: 28px;
      font-family: PingFang SC;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hint {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #9a9a9a;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      img {
        display: none;
      }
    }
  }
}
</style>
